import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzNumberPipe } from './kurz-number.pipe';
import { KurzLengthPipe } from './kurz-length.pipe';
import { KurzWidthPipe } from './kurz-width.pipe';
import { KurzAreaPipe } from './kurz-area.pipe';
import { KurzPricePipe } from './kurz-price.pipe';
import { KurzUnitTranslationPipe } from './kurz-unit-translation.pipe';
import { KurzDatePipe } from './kurz-date.pipe';
import { KurzPricePerUnitPipe } from './kurz-price-per-unit.pipe';
import { KurzFilterPipe } from './kurz-filter.pipe';



@NgModule({
  declarations: [
    KurzNumberPipe,
    KurzLengthPipe,
    KurzWidthPipe,
    KurzAreaPipe,
    KurzPricePipe,
    KurzDatePipe,
    KurzUnitTranslationPipe,
    KurzPricePerUnitPipe,
    KurzFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    KurzNumberPipe,
    KurzLengthPipe,
    KurzWidthPipe,
    KurzAreaPipe,
    KurzPricePipe,
    KurzDatePipe,
    KurzUnitTranslationPipe,
    KurzPricePerUnitPipe,
    KurzFilterPipe
  ]
})
export class KurzUnitPipesModule { }
