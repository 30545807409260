import { Pipe, PipeTransform } from '@angular/core';
import { KurzUnitService } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzNumber'
})
export class KurzNumberPipe implements PipeTransform {

  constructor(private kurzUnitService: KurzUnitService) {}

  transform(value: string | number, maximumFractionDigits = 2, minimumFractionDigits = 0): string {

    let num: number;

    switch(typeof value) {
      case 'number': num = value as number; break;
      case 'string': num = parseFloat(value); break;
      default: num = value as number;
    }

    return this.kurzUnitService.getFormattedNumber(num, maximumFractionDigits, minimumFractionDigits);
  }

}
